window.onload = function () {
    const location = window.location;
    const lastSegment = location.pathname.split('/')[2];
    const quickStart = document.querySelector('#left-navigation-quickstart');
    const demo = document.querySelector('#left-navigation-demo');
    if (quickStart && lastSegment === 'quickstart') {
        quickStart.classList.add('selected');
    }
    if (quickStart && lastSegment === 'demo') {
        demo.classList.add('selected');
    }
};
