var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from 'lit';
import { customElement, state, property } from 'lit/decorators.js';
let MobileNavigationComponent = class MobileNavigationComponent extends LitElement {
    static get styles() {
        const nav = css ` 
          :host {
            display: block;
          }
          div.hamburger {
            color: var(--secondary-color);
            padding-top: 5px;
            display: block;
            border: 1px dashed var(--secondary-color);
            height: 25px;
            width: 30px;
            top: 10px;
            right: 10px;
            z-index: 1;
            transition: top 0.3s;
            transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
          }
          
          div.hamburger:active {
            color: var(--terminal-text);
            border: 1px dashed var(--terminal-text);
          }

          .nav-items {
            border: 1px dashed var(--secondary-color);
            display: block;
            position: fixed;
            top: 50px;
            right: 10px;
            width: 50vw;
            background: var(--background-color-alpha-mobile);
            backdrop-filter: blur(4px);
            z-index: 2;
            padding: 10px;
            transition: right 0.3s;
            transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
          }

          div.hamburger.open {
            color: var(--terminal-text);
          }

          div.hamburger.hide {
            top: -185px;
          }

          .nav-items.hide {
            right: -250px;
          }
        `;
        return [nav];
    }
    toggle() {
        this.open = !this.open;
    }
    handleShow(e) {
        const slot = this.shadowRoot.querySelector('slot');
        const slots = slot.assignedElements({ flatten: true });
        slots.forEach((slotItem) => {
            const item = slotItem;
            item.open = e.target === item;
        });
    }
    render() {
        let navItems;
        if (this.open) {
            navItems = html `<div class="nav-items ${this.hide ? 'hide' : ''}" @sl-show=${this.handleShow}>
        <slot></slot>
      </div>`;
        }
        return html `<div
        class="hamburger ${this.hide ? 'hide' : ''} ${this.open ? 'open' : ''}"
        @click=${this.toggle}
        @keyup=${this.toggle}
      >
        <svg
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1024 768"
        >
          <rect x="213.5" y="20" width="598" height="96" />
          <rect x="212.5" y="260" width="598" height="96" />
          <rect x="212.5" y="500" width="598" height="96" />
        </svg>
      </div>
      ${navItems}`;
    }
};
__decorate([
    property()
], MobileNavigationComponent.prototype, "hide", void 0);
__decorate([
    state()
], MobileNavigationComponent.prototype, "open", void 0);
MobileNavigationComponent = __decorate([
    customElement('mobile-nav')
], MobileNavigationComponent);
export { MobileNavigationComponent };
