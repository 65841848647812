export function checkForDisplayRightNav() {
    // const rightNav: HTMLElement = document.querySelector<HTMLElement>(".right-navigation-container")
    // const mainContentContainer = document.querySelector<HTMLElement>("#main-content")
    // if (window.innerWidth < 1000) {
    //     if (rightNav) {
    //         rightNav.style.display = 'none';
    //     }
    //     if (window.innerWidth < 400) {
    //         mainContentContainer.classList.add('one-column')
    //     } else {
    //         mainContentContainer.classList.add('two-columns')
    //     }
    // } else {
    //     if (rightNav) {
    //         rightNav.style.display = 'block';
    //     }
    //     mainContentContainer.classList.remove('one-column');
    //     mainContentContainer.classList.remove('two-columns');
    // }
}
