var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from 'lit';
import { customElement, state, property } from 'lit/decorators.js';
let TerminalRESTComponent = class TerminalRESTComponent extends LitElement {
    static get styles() {
        const nav = css `
          :host {
            display: flex;
            column-gap: 10px;
            position: relative;
            background-color: black;
            margin: 0;
            font-weight: normal;
            overflow: hidden;
            padding: 1rem 0 1rem 0;
            border: 1px dashed var(--hrcolor);
            font-family: var(--font-stack), monospace;
            overflow-x: auto;
          }
          
          .method {
            color: var(--tertiary-color);
          }
          
          .get {
            color: var(--terminal-text);
          }
          
          .post {
            color: var(--primary-color);
          }
          
          .patch {
            color: var(--terminal-yellow);
          }

          .delete {
            color: var(--error-color);
          }


          pre {
            margin: 0;
            padding-left: 0;
            font-family: var(--font-stack), monospace;
            overflow-x: auto;
          }

          :host::before {
            padding-left: 1rem;
            content: ">";
            color: var(--secondary-color)
          }
          
          .hidden {
            display: none;
          }
          
          @media only screen and (max-width: 1000px) {
            pre {
              
            }
          }
        `;
        return [nav];
    }
    render() {
        var _a;
        return html `
                <span class="method ${(_a = this.method) === null || _a === void 0 ? void 0 : _a.toLowerCase()}">${this.method}</span>
                <pb33f-render-operation-path .path="${this.path}"></pb33f-render-operation-path>    
            `;
    }
};
__decorate([
    property()
], TerminalRESTComponent.prototype, "method", void 0);
__decorate([
    property()
], TerminalRESTComponent.prototype, "path", void 0);
__decorate([
    state()
], TerminalRESTComponent.prototype, "pathRender", void 0);
TerminalRESTComponent = __decorate([
    customElement('terminal-rest-view')
], TerminalRESTComponent);
export { TerminalRESTComponent };
